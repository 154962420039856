import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import ArrowCTA from '../assets/svg/arrow_cta.svg'

const NotFoundPage = () => {
  return(
    <Layout>
      <SEO title="404: Not found"/>
      <div className="privacy-notfound">
        <div className="success-container">
          <h1 className='title'>404: Not Found</h1>
          <p className='main-text'>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <Link className="cta" to="/stories">Check out our Stories <ArrowCTA/></Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
